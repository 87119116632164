import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGauge, faPowerOff, faUser, faKey, faNewspaper, faArrowsToCircle, faTag, faCalendarDays, faSchool, faAward, faBuilding, faMailBulk, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from "react-router-dom";

const menus = [
  {
    name: "Dashboard",
    icon: faGauge,
    url: '/'
  },
  {
    name: "Master Tags",
    icon: faTag,
    url: '/tags'
  },
  {
    name: "News",
    icon: faNewspaper,
    url: '/news'
  },
  {
    name: "Article",
    icon: faArrowsToCircle,
    url: '/article'
  },
  {
    name: "Event",
    icon: faCalendarDays,
    url: '/event'
  },
  {
    name: "Training",
    icon: faSchool,
    url: '/training'
  },
  {
    name: "Purpose",
    icon: faAward,
    url: '/purpose'
  },
  {
    name: "Contact Us",
    icon: faMailBulk,
    url: '/contact/us'
  },
  {
    name: "Subscribed",
    icon: faUsers,
    url: '/subscribe'
  },
  {
    name: "Company",
    icon: faBuilding,
    url: '/company'
  }
];

const Header = () => {

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate("/login");
  };

  return (
    <React.Fragment>
      <div className="sticky-top">
        <header className="navbar navbar-expand-md navbar-light d-print-none">
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar-menu"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
              <Link to="/" style={{ textDecoration: "none" }}>
                <p
                  className="m-0 py-2 text-primary"
                  style={{
                    fontSize: "1.7rem",
                    letterSpacing: 2,
                    fontFamily:
                      'Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif'
                  }}
                >
                  AMCed
                </p>
              </Link>
            </h1>
            <div className="navbar-nav flex-row order-md-last">
              <div className="nav-item d-none d-md-flex me-3">
                <div className="d-flex align-items-center fw-bolder text-primary text-shadow-0-07-05-lightgray">
                  {/* <p class="m-0 p-0 tracking-normal" style="letter-spacing: .5px">Welcome, User</p> */}
                  {/* <p
                    className="m-0 p-0 tracking-normal welcome-user-name"
                    style={{ letterSpacing: ".5px" }}
                  >
                    Welcome, Vasim Dawada
                  </p> */}
                </div>
              </div>
              <div className="d-none d-md-flex">
                <div className="nav-item dropdown d-none d-md-flex me-3">
                  {/* <a
                    href="#"
                    className="nav-link px-0"
                    data-bs-toggle="dropdown"
                    tabIndex={-1}
                    aria-label="Show notifications"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                      <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                    </svg>
                    <span className="badge bg-red" />
                  </a> */}
                  <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Last updates</h3>
                      </div>
                      <div className="list-group list-group-flush list-group-hoverable">
                        <div className="list-group-item">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <span className="status-dot status-dot-animated bg-red d-block" />
                            </div>
                            <div className="col text-truncate">
                              <a href="#" className="text-body d-block">
                                Example 1
                              </a>
                              <div className="d-block text-muted text-truncate mt-n1">
                                Change deprecated html tags to text decoration classes
                                (#29604)
                              </div>
                            </div>
                            <div className="col-auto">
                              <a href="#" className="list-group-item-actions">
                                {/* Download SVG icon from http://tabler-icons.io/i/star */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon text-muted"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="list-group-item">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <span className="status-dot d-block" />
                            </div>
                            <div className="col text-truncate">
                              <a href="#" className="text-body d-block">
                                Example 2
                              </a>
                              <div className="d-block text-muted text-truncate mt-n1">
                                justify-content:between ⇒
                                justify-content:space-between (#29734)
                              </div>
                            </div>
                            <div className="col-auto">
                              <a href="#" className="list-group-item-actions show">
                                {/* Download SVG icon from http://tabler-icons.io/i/star */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon text-yellow"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="list-group-item">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <span className="status-dot d-block" />
                            </div>
                            <div className="col text-truncate">
                              <a href="#" className="text-body d-block">
                                Example 3
                              </a>
                              <div className="d-block text-muted text-truncate mt-n1">
                                Update change-version.js (#29736)
                              </div>
                            </div>
                            <div className="col-auto">
                              <a href="#" className="list-group-item-actions">
                                {/* Download SVG icon from http://tabler-icons.io/i/star */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon text-muted"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="list-group-item">
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <span className="status-dot status-dot-animated bg-green d-block" />
                            </div>
                            <div className="col text-truncate">
                              <a href="#" className="text-body d-block">
                                Example 4
                              </a>
                              <div className="d-block text-muted text-truncate mt-n1">
                                Regenerate package-lock.json (#29730)
                              </div>
                            </div>
                            <div className="col-auto">
                              <a href="#" className="list-group-item-actions">
                                {/* Download SVG icon from http://tabler-icons.io/i/star */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="icon text-muted"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  strokeWidth={2}
                                  stroke="currentColor"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                  <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link d-flex lh-1 text-reset p-0"
                  data-bs-toggle="dropdown"
                  aria-label="Open user menu"
                >
                  <span
                    className="avatar avatar-sm"
                    style={{ backgroundImage: "url(https://images.unsplash.com/photo-1682685797208-c741d58c2eff?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80)" }}
                  />
                  <div className="d-none d-xl-block ps-2">
                    <div className="user-full-name">Amced</div>
                    <div className="mt-1 small text-muted d-flex align-items-center">
                      <span
                        className="bg-success me-1"
                        style={{ width: 7, height: 7, borderRadius: "50%" }}
                      />
                      <small>Admin</small>
                    </div>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <Link to="/reset/password" className="dropdown-item">
                    {/* <i className="ti ti-key icon me-2" /> */}
                    <FontAwesomeIcon icon={faKey} className="me-2 mt-1" />
                    Change Password
                  </Link>
                  {/* <a href="#" className="dropdown-item">
                    <FontAwesomeIcon icon={faUser} className="me-2 mt-1" />
                    Profile &amp; Account
                  </a> */}
                  <a className="dropdown-item" onClick={e => handleLogout()}>
                    {/* <i className="ti ti-power icon me-2" /> */}
                    <FontAwesomeIcon icon={faPowerOff} className="me-2 mt-1" />
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="navbar-expand-md">
          <div className="collapse navbar-collapse" id="navbar-menu">
            <div className="navbar navbar-light">
              <div className="container-fluid">
                <ul className="navbar-nav">
                  {
                    menus.map(m => (
                      <li className={`nav-item ${window.location.pathname === m.url && 'active'}`} key={m.url}>
                        <Link className={`nav-link active}`} to={m.url}>
                          <span className="nav-link-icon d-md-none d-lg-inline-block">
                            <FontAwesomeIcon icon={m.icon} />
                          </span>
                          <span className="nav-link-title">{m.name}</span>
                        </Link>
                      </li>
                    ))
                  }
                  <li className="nav-item">
                    <a className="nav-link" style={{ cursor: "pointer" }} onClick={e => handleLogout()}>
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <FontAwesomeIcon icon={faPowerOff} />
                      </span>
                      <span className="nav-link-title">Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};

export default Header;