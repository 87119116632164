import * as subscribeActions from "./subscribe.actions";

export const subscriberFeatureKey = 'subscribeInfo';

const initialState = {
    loading: false,
    errorMsg: "",
    list: [],
    isStatusChange: false
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case subscribeActions.GET_SUBSCRIBER_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case subscribeActions.GET_SUBSCRIBER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data
            }

        case subscribeActions.GET_SUBSCRIBER_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case subscribeActions.GET_SUBSCRIBE_DELETE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case subscribeActions.GET_SUBSCRIBE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                isStatusChange: true
            }

        case subscribeActions.GET_SUBSCRIBE_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "IS_STATUS_CHANGE":
            return {
                ...state,
                isStatusChange: false
            }

        default: return state;
    }
};