import axios from "axios";
import { toast } from "react-toastify";

export const GET_SUBSCRIBER_LIST_REQUEST = 'GET_SUBSCRIBER_LIST_REQUEST';
export const GET_SUBSCRIBER_LIST_SUCCESS = 'GET_SUBSCRIBER_LIST_SUCCESS';
export const GET_SUBSCRIBER_LIST_FAILURE = 'GET_SUBSCRIBER_LIST_FAILURE';

export const GET_SUBSCRIBE_DELETE_REQUEST = 'GET_SUBSCRIBE_DELETE_REQUEST';
export const GET_SUBSCRIBE_DELETE_SUCCESS = 'GET_SUBSCRIBE_DELETE_SUCCESS';
export const GET_SUBSCRIBE_DELETE_FAILURE = 'GET_SUBSCRIBE_DELETE_FAILURE';

export const getSubscriberList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_SUBSCRIBER_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/verified/list/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_SUBSCRIBER_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_SUBSCRIBER_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const subscribeDelete = (id, setSelectedId, setModal) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_STATUS_CHANGE" });
            dispatch({ type: GET_SUBSCRIBE_DELETE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/verify/delete/${id}/v1`;
            const response = await axios.delete(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_SUBSCRIBE_DELETE_SUCCESS, payload: response.data });
            setSelectedId("");
            setModal(false);
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_SUBSCRIBE_DELETE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};