import React from 'react';
import DataTable from 'react-data-table-component';

const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
      backgroundColor: '#41adb6',
      color: "#fff"
    },
  },
}

const DataTableComponent = ({ column, data, ...props }) => {
  return (
    <>
      <DataTable
        columns={column}
        data={data}
        pagination
        highlightOnHover
        customStyles={tableCustomStyles}
        dense
        {...props}
      />
    </>
  )
};

export default DataTableComponent;