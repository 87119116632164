import axios from "axios";
import { toast } from "react-toastify";

export const GET_MASTER_TAGS_LIST_REQUEST = 'GET_MASTER_TAGS_LIST_REQUEST';
export const GET_MASTER_TAGS_LIST_SUCCESS = 'GET_MASTER_TAGS_LIST_SUCCESS';
export const GET_MASTER_TAGS_LIST_FAILURE = 'GET_MASTER_TAGS_LIST_FAILURE';

export const CREATE_MASTER_TAG_REQUEST = 'CREATE_MASTER_TAG_REQUEST';
export const CREATE_MASTER_TAG_SUCCESS = 'CREATE_MASTER_TAG_SUCCESS';
export const CREATE_MASTER_TAG_FAILURE = 'CREATE_MASTER_TAG_FAILURE';

export const UPDATE_MASTER_TAG_REQUEST = 'UPDATE_MASTER_TAG_REQUEST';
export const UPDATE_MASTER_TAG_SUCCESS = 'UPDATE_MASTER_TAG_SUCCESS';
export const UPDATE_MASTER_TAG_FAILURE = 'UPDATE_MASTER_TAG_FAILURE';

export const CHANGE_TAG_STATUS_REQUEST = 'CHANGE_TAG_STATUS_REQUEST';
export const CHANGE_TAG_STATUS_SUCCESS = 'CHANGE_TAG_STATUS_SUCCESS';
export const CHANGE_TAG_STATUS_FAILURE = 'CHANGE_TAG_STATUS_FAILURE';

export const getMasterTagList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_MASTER_TAGS_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/master/tag/v1`;
            const response = await axios.get(url);
            dispatch({ type: GET_MASTER_TAGS_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_MASTER_TAGS_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const createMasterTag = (data, createModalToggle, confirmToggle, setName) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CREATE_MASTER_TAG_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/master/tag/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_MASTER_TAG_SUCCESS, payload: response.data });
            createModalToggle();
            confirmToggle();
            setName("");
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_MASTER_TAG_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const editMasterTag = (id, data, createModalToggle, confirmToggle, setName, setIsEdit, setSelectedData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_MASTER_TAG_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/master/tag/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_MASTER_TAG_SUCCESS, payload: response.data });
            createModalToggle();
            confirmToggle();
            setName("");
            setIsEdit(false);
            setSelectedData({});
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_MASTER_TAG_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const changeTagStatus = (id, data, confirmToggle, setSelectedData, setIsStatusChange) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CHANGE_TAG_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/master/tag/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CHANGE_TAG_STATUS_SUCCESS, payload: response.data });
            confirmToggle();
            setIsStatusChange(false);
            setSelectedData({});
        } catch (error) {
            console.error(error);
            dispatch({ type: CHANGE_TAG_STATUS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};