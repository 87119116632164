import axios from "axios";
import { toast } from "react-toastify";

export const GET_ARTICLE_LIST_REQUEST = 'GET_ARTICLE_LIST_REQUEST';
export const GET_ARTICLE_LIST_SUCCESS = 'GET_ARTICLE_LIST_SUCCESS';
export const GET_ARTICLE_LIST_FAILURE = 'GET_ARTICLE_LIST_FAILURE';

export const CREATE_ARTICLE_REQUEST = 'CREATE_ARTICLE_REQUEST';
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
export const CREATE_ARTICLE_FAILURE = 'CREATE_ARTICLE_FAILURE';

export const GET_ONE_ARTICLE_REQUEST = 'GET_ONE_ARTICLE_REQUEST';
export const GET_ONE_ARTICLE_SUCCESS = 'GET_ONE_ARTICLE_SUCCESS';
export const GET_ONE_ARTICLE_FAILURE = 'GET_ONE_ARTICLE_FAILURE';

export const UPDATE_ARTICLE_REQUEST = 'UPDATE_ARTICLE_REQUEST';
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS';
export const UPDATE_ARTICLE_FAILURE = 'UPDATE_ARTICLE_FAILURE';

export const CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_FAILURE = 'CHANGE_STATUS_FAILURE';

export const getArticleList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ARTICLE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/article/v1`;
            const response = await axios.get(url);
            dispatch({ type: GET_ARTICLE_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ARTICLE_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const createArticle = (data, setData, setSelectedOptions, confirmToggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CREATE_ARTICLE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/article/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_ARTICLE_SUCCESS, payload: response.data });
            setData({
                tags: [],
                title: "",
                displayImage: "",
                description: ""
            });
            setSelectedOptions([]);
            confirmToggle();
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_ARTICLE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getOneArticle = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ONE_ARTICLE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/article/${id}/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_ONE_ARTICLE_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ONE_ARTICLE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateArticle = (id, data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_ARTICLE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/article/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_ARTICLE_SUCCESS, payload: response.data });
            navigate("/article");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_ARTICLE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const changeStatus = (id, active) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_STATE" });
            dispatch({ type: CHANGE_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/article/${id}/v1`;
            const response = await axios.put(url, { active }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CHANGE_STATUS_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: CHANGE_STATUS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};