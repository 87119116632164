import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import * as adminActions from "../../redux/admin/admin.actions";
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'reactstrap';

const ForgotPassword = () => {

  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const loading = useSelector(state => state.adminInfo.loading);

  const handleForgotPassword = (e) => {
    e.preventDefault();
    dispatch(adminActions.forgotPassword(email));
  };

  return (
    <React.Fragment>
      <div
        id="page-loader"
        style={{
          background:
            'rgba(0,0,0,0.75) url("<?= base_url()?>/img/loader.gif") no-repeat center center'
        }}
      ></div>
      <div className="page page-center">
        <div className="container-tight py-4">
          <div className="text-center mb-4">
            <a href="." className="navbar-brand navbar-brand-autodark">
              <img src="./assets/img/logo.svg" height={36} alt="" />
            </a>
          </div>
          <form
            className="card card-md"
            onSubmit={handleForgotPassword}
          >
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Verify OTP</h2>
              <div className="mb-0">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  style={{ boxShadow: "none" }}
                  tabIndex={1}
                  placeholder="Enter Email"
                  name="username"
                  autoComplete="off"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <div className="form-footer mt-3 text-center">
                <button
                  type="submit"
                  className="btn btn-primary w-100 no-shadow mb-4"
                  style={{ boxShadow: "none" }}
                  tabIndex={2}
                  id="sendOtpBtn__ID"
                  disabled={loading}
                >
                  {
                    loading ?

                      <Spinner
                        color="light"
                      >
                        Loading...
                      </Spinner>
                      :
                      <><FontAwesomeIcon icon={faMailBulk} style={{ marginRight: "10px" }} /> Send Email</>
                  }
                </button>
              </div>
              <div className="text-center text-muted mt-3">
                Forget it,{" "}
                <Link
                  style={{ fontWeight: 550 }}
                  className="text-primary"
                  to="/login"
                >
                  Send me Back
                </Link>{" "}
                to the Sign In Screen.
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ForgotPassword;