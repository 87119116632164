import * as tagActions from "./tag.actions";

export const masterTagFeatureKey = 'tagInfo';

const initialState = {
    loading: false,
    errorMsg: "",
    list: []
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case tagActions.GET_MASTER_TAGS_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case tagActions.GET_MASTER_TAGS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data
            }

        case tagActions.GET_MASTER_TAGS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case tagActions.CREATE_MASTER_TAG_REQUEST:
            return {
                ...state,
                loading: true
            }

        case tagActions.CREATE_MASTER_TAG_SUCCESS:
            return {
                ...state,
                loading: false,
                list: [payload.data, ...state.list]
            }

        case tagActions.CREATE_MASTER_TAG_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case tagActions.UPDATE_MASTER_TAG_REQUEST:
            return {
                ...state,
                loading: true
            }

        case tagActions.UPDATE_MASTER_TAG_SUCCESS:
            let index = state.list.findIndex(l => l._id.toString() == payload.data._id.toString());
            state.list.splice(index, 1, payload.data);
            return {
                ...state,
                loading: false
            }

        case tagActions.UPDATE_MASTER_TAG_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case tagActions.CHANGE_TAG_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case tagActions.CHANGE_TAG_STATUS_SUCCESS:
            let findIndex = state.list.findIndex(l => l._id.toString() == payload.data._id.toString());
            state.list.splice(findIndex, 1, payload.data);
            return {
                ...state,
                loading: false
            }

        case tagActions.CHANGE_TAG_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};