import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as contactActions from "../../redux/contact/contact.actions";
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import HeaderTitle from '../../components/common/HeaderTitle';
import Loader from '../../lottie/Loader';
import { faArrowLeft, faCalendarDays } from '@fortawesome/free-solid-svg-icons';

const ContactView = () => {

  const [data, setData] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();

  const loading = useSelector(state => state.contactInfo.loading);
  const details = useSelector(state => state.contactInfo.details);

  useEffect(() => {
    if (id) {
      dispatch(contactActions.getContactDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && Object.keys(details)?.length > 0) {
      setData(details);
    } else {
      setData({});
    }
  }, [details]);

  return (
    <Container fluid className='mt-3'>
      <HeaderTitle buttonTex={""} buttonIcon={faArrowLeft} icon={faCalendarDays} route={"/contact/us"} title={"Contact Us Details"} />
      <Row>
        <Col>
          {
            loading ?
              <Loader /> :
              <>
                <Card>
                  <CardBody>
                    <p><span style={{ fontWeight: 'bold' }}>Name :</span> {data?.name}</p>
                    <p><span style={{ fontWeight: 'bold' }}>Email :</span> {data?.email}</p>
                    <p><span style={{ fontWeight: 'bold' }}>Phone :</span> {data?.phone}</p>
                    <p><span style={{ fontWeight: 'bold' }}>Institution or Organisation :</span> {data?.company}</p>
                    <p><span style={{ fontWeight: 'bold' }}>Message :</span> {data?.message}</p>
                  </CardBody>
                </Card>
              </>
          }
        </Col>
      </Row>
    </Container>
  )
};

export default ContactView;