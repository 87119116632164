import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const HeaderTitle = ({ icon, title, route, buttonTex, buttonIcon }) => {
  return (
    <>
      <div className="page-header d-print-none my-3">
        <div className="row g-2 align-items-center d-flex justify-content-between">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div className="d-inline-flex">
              <span
                className="bg-primary text-white rounded d-flex justify-content-center align-items-center"
                style={{ width: 35, height: 35 }}
              >
                <FontAwesomeIcon icon={icon} />
              </span>
              <h2
                className="page-title ms-3 text-primary font-weight-bold text-capitalize"
                style={{
                  textShadow: "0px .7px .5px lightgray",
                  wordWrap: "break-word"
                }}
              >
                {title}
              </h2>
            </div>
            <div className="btn-list">
              <Link
                to={route}
                type="button"
                className="btn btn-primary me-1 shadow-none"
                title="Add Product"
              >
                <FontAwesomeIcon icon={buttonIcon} />
                <span className="ms-1 d-none d-md-block d-lg-block d-xl-block">
                  {buttonTex}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeaderTitle;