import React from 'react';
import * as tagActions from "../../redux/masterTag/tag.actions";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus, faTag } from '@fortawesome/free-solid-svg-icons';
import { Badge, Button, Col, Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import moment from 'moment';
import Loader from '../../lottie/Loader';
import DataTableComponent from '../../components/common/DataTableComponent';
import { useState } from 'react';
import ConfirmModal from '../../components/common/ConfirmModal';

const Tag = () => {

  const [name, setName] = useState("");
  const [list, setList] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isStatusChange, setIsStatusChange] = useState(false);
  const [selecteData, setSelectedData] = useState({});
  const [filterText, setFilterText] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const dispatch = useDispatch();

  const createModalToggle = () => setCreateModal(!createModal);
  const confirmToggle = () => setConfirmModal(!confirmModal);

  const loading = useSelector(state => state.tagInfo.loading);
  const tags = useSelector(state => state.tagInfo.list);

  useEffect(() => {
    dispatch(tagActions.getMasterTagList());
  }, []);

  useEffect(() => {
    if (tags?.length > 0) {
      setList(tags);
    } else {
      setList([]);
    }
  }, [tags]);

  useEffect(() => {
    if (tags?.length > 0) {
      if (filterText) {
        const filterData = tags.filter(a => a.name.toLowerCase().includes(filterText.toLowerCase()));
        setList(filterData);
      } else {
        setList(tags);
      }
    }
  }, [filterText]);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className='col-12 col-lg-4 col-md-6'>
        <Input type='text' placeholder='Search Title here' className='shadow-none' value={filterText} onChange={e => setFilterText(e.target.value)} />
      </div>
    );
  }, [filterText]);

  const handleSave = () => {
    if (isStatusChange) {
      dispatch(tagActions.changeTagStatus(selecteData?._id, { active: !selecteData?.active }, confirmToggle, setSelectedData, setIsStatusChange));
    } else {
      console.log('hello')
      if (isEdit) {
        dispatch(tagActions.editMasterTag(selecteData._id, { name }, createModalToggle, confirmToggle, setName, setIsEdit, setSelectedData));
      } else {
        dispatch(tagActions.createMasterTag({ name }, createModalToggle, confirmToggle, setName));
      }
    }
  };

  const column = [
    {
      name: 'Title',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Created At',
      selector: row => moment(new Date(row.createdAt)).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => (<>
        <Badge className={`${row?.active ? 'bg-success' : 'bg-danger'}`}>{row?.active ? "Active" : "Inactive"}</Badge>
      </>),
      sortable: true,
    },
    {
      name: 'Action',
      selector: row => (<>
        <span onClick={e => {
          setIsEdit(true);
          setSelectedData(row);
          setName(row?.name);
          createModalToggle();
        }}>
          <FontAwesomeIcon icon={faPen} className='text-warning' style={{ cursor: "pointer" }} />
        </span>
        <Button size='sm' style={{ marginLeft: "10px" }} color='primary' className='shadow-none' onClick={e => {
          setSelectedData(row);
          confirmToggle();
          setIsStatusChange(true);
        }}>Change Status</Button>
      </>)
    }
  ];

  return (
    <React.Fragment>
      <Container fluid>
        {
          loading ?
            <Loader /> :
            <React.Fragment>
              {/* header */}
              <div className="page-header d-print-none my-3">
                <div className="row g-2 align-items-center d-flex justify-content-between">
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <div className="d-inline-flex">
                      <span
                        className="bg-primary text-white rounded d-flex justify-content-center align-items-center"
                        style={{ width: 35, height: 35 }}
                      >
                        <FontAwesomeIcon icon={faTag} />
                      </span>
                      <h2
                        className="page-title ms-3 text-primary font-weight-bold text-capitalize"
                        style={{
                          textShadow: "0px .7px .5px lightgray",
                          wordWrap: "break-word"
                        }}
                      >
                        Master Tag
                      </h2>
                    </div>
                    <div className="btn-list">
                      <button
                        type="button"
                        className="btn btn-primary me-1 shadow-none"
                        title="Add Product"
                        onClick={e => {
                          createModalToggle();
                          setIsEdit(false);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        <span className="ms-1 d-none d-md-block d-lg-block d-xl-block">
                          Create Master Tag
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Row>
                <Col>
                  <DataTableComponent column={column} data={list} subHeaderComponent={subHeaderComponentMemo} subHeader />
                </Col>
              </Row>
            </React.Fragment>
        }
      </Container>
      <ConfirmModal toggle={confirmToggle} modal={confirmModal} header={"Article"} handleSave={handleSave} loading={loading} />
      {/* create edit modal  */}
      <Modal isOpen={createModal} toggle={createModalToggle} centered size='sm' backdrop={"static"}>
        <ModalHeader toggle={e => {
          createModalToggle();
          setName("");
        }}>
          {isEdit ? "Edit" : "Create"} Master Tag
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Name</Label>
              <Input type='text' value={name} required onChange={e => setName(e.target.value)} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-center'>
          <Button color="primary" size='sm' onClick={confirmToggle}>
            {
              loading ?
                <Spinner
                  color="light"
                  size="sm"
                >
                  Loading...
                </Spinner> :
                isEdit ? "Update" : "Save"
            }
          </Button>{' '}
          <Button color="secondary" size='sm' onClick={e => {
            createModalToggle();
            setName("");
          }}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
};

export default Tag;