import axios from "axios";
import { toast } from "react-toastify";

export const GET_TRAINING_LIST_REQUEST = 'GET_TRAINING_LIST_REQUEST';
export const GET_TRAINING_LIST_SUCCESS = 'GET_TRAINING_LIST_SUCCESS';
export const GET_TRAINING_LIST_FAILURE = 'GET_TRAINING_LIST_FAILURE';

export const CREATE_TRAINING_REQUEST = 'CREATE_TRAINING_REQUEST';
export const CREATE_TRAINING_SUCCESS = 'CREATE_TRAINING_SUCCESS';
export const CREATE_TRAINING_FAILURE = 'CREATE_TRAINING_FAILURE';

export const GET_ONE_TRAINING_REQUEST = 'GET_ONE_TRAINING_REQUEST';
export const GET_ONE_TRAINING_SUCCESS = 'GET_ONE_TRAINING_SUCCESS';
export const GET_ONE_TRAINING_FAILURE = 'GET_ONE_TRAINING_FAILURE';

export const UPDATE_TRAINING_REQUEST = 'UPDATE_TRAINING_REQUEST';
export const UPDATE_TRAINING_SUCCESS = 'UPDATE_TRAINING_SUCCESS';
export const UPDATE_TRAINING_FAILURE = 'UPDATE_TRAINING_FAILURE';

export const CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_FAILURE = 'CHANGE_STATUS_FAILURE';

export const getTrainingList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_TRAINING_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/training/v1`;
            const response = await axios.get(url);
            dispatch({ type: GET_TRAINING_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_TRAINING_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const createTraining = (data, setData, confirmToggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CREATE_TRAINING_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/training/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_TRAINING_SUCCESS, payload: response.data });
            setData({
                tags: [],
                title: "",
                displayImage: "",
                description: ""
            });
            confirmToggle();
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_TRAINING_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getOneTraining = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ONE_TRAINING_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/training/${id}/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_ONE_TRAINING_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ONE_TRAINING_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateTraining = (id, data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_TRAINING_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/training/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_TRAINING_SUCCESS, payload: response.data });
            navigate("/training");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_TRAINING_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const changeStatus = (id, active) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_STATE" });
            dispatch({ type: CHANGE_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/training/${id}/v1`;
            const response = await axios.put(url, { active }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CHANGE_STATUS_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: CHANGE_STATUS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};