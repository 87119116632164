import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import * as trainingActions from "../../redux/training/training.actions";
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../components/common/ConfirmModal';
import { faArrowLeft, faSchool } from '@fortawesome/free-solid-svg-icons';
import HeaderTitle from '../../components/common/HeaderTitle';
import JoditEditorComponent from '../../components/common/JoditEditorComponent';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../lottie/Loader';
import moment from 'moment';
import { checkImageDimensions, uploadMulterImage } from '../../helper';
import { toast } from 'react-toastify';

const EditTraining = () => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState({
    title: "",
    displayImage: "",
    description: "",
    courseMode: "",
    trainingDate: "",
    shortDescription: ""
  });
  const dispatch = useDispatch();
  const navigte = useNavigate();
  const { id } = useParams();

  const confirmToggle = () => setConfirmModal(!confirmModal);

  const oneData = useSelector(state => state.trainingInfo.data);
  const loading = useSelector(state => state.trainingInfo.loading);

  useEffect(() => {
    dispatch(trainingActions.getOneTraining(id));
  }, [id]);

  useEffect(() => {
    if (oneData && Object.keys(oneData).length > 0) {
      setData({
        ...data,
        title: oneData.title,
        displayImage: oneData.displayImage,
        description: oneData.description,
        courseMode: oneData.courseMode,
        shortDescription: oneData.shortDescription,
        trainingDate: oneData.trainingDate ? moment(new Date(oneData.trainingDate)).format("YYYY-MM-DD") : '-'
      });
    }
  }, [oneData]);

  const handleUpdateTraining = (e) => {
    e.preventDefault();
    confirmToggle();
  };

  const handleSave = async () => {
    if (!file) {
      dispatch(trainingActions.updateTraining(id, data, navigte));
    } else {
      const checkImageDimension = await checkImageDimensions(file);
      if (!checkImageDimension.success) {
        toast.error(`The Training Image must be below or equal to 600 pixels in width and 400 pixels in height. Current dimension is ${checkImageDimension.width} x ${checkImageDimension.height}.`);
        return;
      }
      const imageResponse = await uploadMulterImage(file, 'training');
      if (imageResponse.status === false) {
        toast("Something went wrong with image");
        return;
      }
      dispatch(trainingActions.updateTraining(id, { ...data, displayImage: imageResponse.data }, navigte));
    }
  };

  return (
    <React.Fragment>
      {
        loading ?
          <Loader /> :
          <React.Fragment>
            <Container fluid className='mt-3'>
              <HeaderTitle buttonTex={""} buttonIcon={faArrowLeft} icon={faSchool} route={"/training"} title={"Edit Training"} />
              <Row>
                <Col>
                  {
                    oneData && Object.keys(oneData).length > 0 &&
                    <form onSubmit={e => handleUpdateTraining(e)}>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="mb-3">
                            <label className="form-label required">Training Title</label>
                            <input type="text" className="form-control no-shadow" inputMode="text" autoComplete="off" required tabIndex="1" value={data.title} onChange={e => setData({ ...data, title: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="mb-3">
                            <label className="form-label required">Display Image{" "}<span className="text-danger">(Dimension : 600 x 400)</span></label>
                            <input type="file" className="form-control no-shadow" tabIndex="3" accept="image/jpg, image/jpeg, image/png, image/webp" onChange={e => setFile(e.target.files[0])} />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="mb-3">
                            <label className="form-label required">Course Mode</label>
                            <select type="select" className="form-control no-shadow" inputMode="text" autoComplete="off" required tabIndex="1" value={data.courseMode} onChange={e => setData({ ...data, courseMode: e.target.value })}>
                              <option value="">Select</option>
                              <option value="Offline">Offline</option>
                              <option value="Online">Online</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Training Date</label>
                            <input type="date" className="form-control no-shadow" inputMode="text" autoComplete="off" tabIndex="1" value={data.trainingDate} onChange={e => setData({ ...data, trainingDate: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label required">Short Description</label>
                            <textarea className="form-control no-shadow" inputMode="text" autoComplete="off" required tabIndex="1" value={data.shortDescription} onChange={e => setData({ ...data, shortDescription: e.target.value })}></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label required">Description</label>
                            <JoditEditorComponent value={data} setValue={setData} />
                          </div>
                        </div>
                        <div className="col-12 text-center">
                          <button type="submit" className="btn btn-primary ms-auto shadow-none mb-3">Save</button>
                        </div>
                      </div>
                    </form>
                  }
                </Col>
              </Row>
            </Container>
            <ConfirmModal toggle={confirmToggle} modal={confirmModal} header={"Training"} handleSave={handleSave} loading={loading} />
          </React.Fragment>
      }
    </React.Fragment>
  )
};

export default EditTraining;