import { combineReducers } from "redux";
import * as adminReducers from "./admin/admin.reducers";
import * as articleReducers from "./article/article.reducers";
import * as masterTagReducers from "./masterTag/tag.reducers";
import * as newsReducers from "./news/news.reducers";
import * as eventReducers from "./event/event.reducers";
import * as trainingReducers from "./training/training.reducers";
import * as purposeReducers from "./purpose/purpose.reducers";
import * as companyReducers from "./company/company.reducers";
import * as contactReducers from "./contact/contact.reducers";
import * as subscribeReducers from "./subscribe/subscribe.reducers";

export const rootReducer = combineReducers({
    [adminReducers.adminFeatureKey]: adminReducers.reducer,
    [articleReducers.articleFeatureKey]: articleReducers.reducer,
    [masterTagReducers.masterTagFeatureKey]: masterTagReducers.reducer,
    [newsReducers.newsFeatureKey]: newsReducers.reducer,
    [eventReducers.eventFeatureKey]: eventReducers.reducer,
    [trainingReducers.trainingFeatureKey]: trainingReducers.reducer,
    [purposeReducers.purposeFeatureKey]: purposeReducers.reducer,
    [companyReducers.companyFeatureKey]: companyReducers.reducer,
    [contactReducers.contactFeatureKey]: contactReducers.reducer,
    [subscribeReducers.subscriberFeatureKey]: subscribeReducers.reducer
});