import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import * as eventActions from "../../redux/event/event.actions";
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../components/common/ConfirmModal';
import { faArrowLeft, faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import HeaderTitle from '../../components/common/HeaderTitle';
import JoditEditorComponent from '../../components/common/JoditEditorComponent';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../lottie/Loader';
import moment from 'moment';
import { toast } from 'react-toastify';
import { checkImageDimensions, uploadMulterImage } from '../../helper';
import Flatpickr from "react-flatpickr";

const EditEvent = () => {

  const [confirmModal, setConfirmModal] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState({
    title: "",
    displayImage: "",
    description: "",
    tickets: "",
    agenda: "",
    organizer: "",
    location: "",
    startDateTime: "",
    endDateTime: "",
    shortDescription: ""
  });
  const dispatch = useDispatch();
  const navigte = useNavigate();
  const { id } = useParams();

  const confirmToggle = () => setConfirmModal(!confirmModal);

  const oneData = useSelector(state => state.eventInfo.data);
  const loading = useSelector(state => state.eventInfo.loading);

  useEffect(() => {
    dispatch(eventActions.getOneEvent(id));
  }, [id]);

  useEffect(() => {
    if (oneData && Object.keys(oneData).length > 0) {
      setData({
        ...data,
        title: oneData.title,
        description: oneData.description,
        displayImage: oneData.displayImage,
        tickets: oneData.tickets,
        agenda: oneData.agenda,
        organizer: oneData.organizer,
        location: oneData.location,
        startDateTime: oneData.startDateTime,
        endDateTime: oneData.endDateTime,
        shortDescription: oneData.shortDescription
      });
    }
  }, [oneData]);

  const handleUpdateEvent = (e) => {
    e.preventDefault();
    confirmToggle();
  };

  const handleSave = async () => {
    if (!file) {
      dispatch(eventActions.updateEvent(id, data, navigte));
    } else {
      const checkImageDimension = await checkImageDimensions(file);
      if (!checkImageDimension.success) {
        toast.error(`The Event Image must be below or equal to 600 pixels in width and 400 pixels in height. Current dimension is ${checkImageDimension.width} x ${checkImageDimension.height}.`);
        return;
      }
      const imageResponse = await uploadMulterImage(file, 'event');
      if (imageResponse.status === false) {
        toast("Something went wrong with image");
        return;
      }
      dispatch(eventActions.updateEvent(id, { ...data, displayImage: imageResponse.data }, navigte));
    };
  }

  const clearDateTime = (fieldName) => {
    setData({ ...data, [fieldName]: null });
  };
  return (
    <React.Fragment>
      {
        loading ?
          <Loader /> :
          <React.Fragment>
            <Container fluid className='mt-3'>
              <HeaderTitle buttonTex={""} buttonIcon={faArrowLeft} icon={faCalendarDays} route={"/event"} title={"Edit Event"} />
              <Row>
                <Col>
                  {
                    oneData && Object.keys(oneData).length > 0 &&
                    <form onSubmit={e => handleUpdateEvent(e)}>
                      <div className="row">
                        <div className="col-12 col-md-4">
                          <div className="mb-3">
                            <label className="form-label required">Event Title</label>
                            <input type="text" className="form-control no-shadow" inputMode="text" autoComplete="off" required tabIndex="1" value={data.title} onChange={e => setData({ ...data, title: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Organizer</label>
                            <input type="text" className="form-control no-shadow" inputMode="text" autoComplete="off" tabIndex="1" value={data.organizer} onChange={e => setData({ ...data, organizer: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="mb-3">
                            <label className="form-label required">Display Image{" "}<span className="text-danger">(Dimension : 600 x 400)</span></label>
                            <input type="file" className="form-control no-shadow" tabIndex="3" accept="image/jpg, image/jpeg, image/png, image/webp" onChange={e => setFile(e.target.files[0])} />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Start Date & Time
                            {data.startDateTime && (
                              <button type="button" onClick={() => clearDateTime('startDateTime')} className="btn-link fw-bold float-end" aria-label="Clear">
                                Clear
                              </button>
                            )}
                            </label>
                            {/* <input type="datetime-local" className="form-control no-shadow" inputMode="text" autoComplete="off" required tabIndex="1" value={data.startDateTime} onChange={e => setData({ ...data, startDateTime: e.target.value })} /> */}
                            <Flatpickr
                              className='form-control'
                              data-enable-time
                              placeholder='dd-mm-yyyy h-m'
                              options={{
                                enableTime: true,
                                dateFormat: "d-m-Y h:i K"
                              }}
                              value={data.startDateTime}
                              onChange={([date]) => {
                                setData({ ...data, startDateTime: new Date(date) })
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">End Date & Time
                            {data.endDateTime && (
                              <button type="button" onClick={() => clearDateTime('endDateTime')} className="btn-link fw-bold float-end" aria-label="Clear">
                                Clear
                              </button>
                            )}
                            </label>
                            {/* <input type="datetime-local" className="form-control no-shadow" inputMode="text" autoComplete="off" required tabIndex="1" value={data.endDateTime} onChange={e => setData({ ...data, endDateTime: e.target.value })} /> */}
                            <Flatpickr
                              className='form-control'
                              data-enable-time
                              placeholder='dd-mm-yyyy h-m'
                              options={{
                                enableTime: true,
                                dateFormat: "d-m-Y h:i K"
                              }}
                              value={data.endDateTime}
                              onChange={([date]) => {
                                setData({ ...data, endDateTime: new Date(date) })
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Location</label>
                            <input type="text" className="form-control no-shadow" inputMode="text" autoComplete="off" tabIndex="1" value={data.location} onChange={e => setData({ ...data, location: e.target.value })} />
                          </div>
                        </div>
                        {/* <div className="col-12 col-md-4">
                          <div className="mb-3">
                            <label className="form-label required">Agenda</label>
                            <input type="text" className="form-control no-shadow" inputMode="text" autoComplete="off" required tabIndex="1" value={data.agenda} onChange={e => setData({ ...data, agenda: e.target.value })} />
                          </div>
                        </div> */}
                        <div className="col-12 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Ticket</label>
                            <input type="text" className="form-control no-shadow" inputMode="text" autoComplete="off" tabIndex="1" value={data.tickets} onChange={e => setData({ ...data, tickets: e.target.value })} />
                          </div>
                        </div>
                        {/* <div className="col-12 col-md-6">
                          <div className="mb-3">
                            <label className="form-label required">Event Date</label>
                            <input type="date" className="form-control no-shadow" inputMode="text" autoComplete="off" required tabIndex="1" value={data?.eventDate} onChange={e => setData({ ...data, eventDate: e.target.value })} />
                          </div>
                        </div> */}
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">Agenda</label>
                            <textarea className="form-control no-shadow" inputMode="text" autoComplete="off" tabIndex="1" value={data.agenda} onChange={e => setData({ ...data, agenda: e.target.value })} ></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label required">Short Description</label>
                            <textarea className="form-control no-shadow" inputMode="text" autoComplete="off" required tabIndex="1" value={data.shortDescription} onChange={e => setData({ ...data, shortDescription: e.target.value })} ></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label required">Description</label>
                            <JoditEditorComponent value={data} setValue={setData} />
                          </div>
                        </div>
                        <div className="col-12 text-center">
                          <button type="submit" className="btn btn-primary ms-auto shadow-none mb-3">Save</button>
                        </div>
                      </div>
                    </form>
                  }
                </Col>
              </Row>
            </Container>
            <ConfirmModal toggle={confirmToggle} modal={confirmModal} header={"Event"} handleSave={handleSave} loading={loading} />
          </React.Fragment>
      }
    </React.Fragment>
  )
};

export default EditEvent;