import React from 'react';
import DataTableComponent from '../../components/common/DataTableComponent';
import { Badge, Col, Container, Input, Row, Button } from 'reactstrap';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as trainingActions from "../../redux/training/training.actions";
import { faSchool, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import HeaderTitle from '../../components/common/HeaderTitle';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Loader from '../../lottie/Loader';
import ConfirmModal from '../../components/common/ConfirmModal';
import { useRef } from 'react';

const Training = () => {

  const [filterText, setFilterText] = useState('');
  const [listData, setListData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const dispatch = useDispatch();

  const confirmToggle = () => setConfirmModal(!confirmModal);
  const trainingList = useSelector(state => state.trainingInfo.list);
  const loading = useSelector(state => state.trainingInfo.loading);
  const isStatusChange = useSelector(state => state.trainingInfo.isStatusChange);
  const prevProps = useRef({ isStatusChange }).current;

  useEffect(() => {
    dispatch(trainingActions.getTrainingList());
  }, []);

  useEffect(() => {
    if (trainingList.length > 0) {
      setListData(trainingList);
    } else {
      setListData([]);
    }
  }, [trainingList]);

  useEffect(() => {
    if (trainingList.length > 0) {
      if (filterText) {
        const filterData = trainingList.filter(a => a.title.toLowerCase().includes(filterText.toLowerCase()));
        setListData(filterData);
      } else {
        setListData(trainingList);
      }
    }
  }, [filterText]);

  useEffect(() => {
    if (prevProps.isStatusChange !== isStatusChange) {
      if (isStatusChange) {
        dispatch(trainingActions.getTrainingList());
        setFilterText("");
      }
    }

    return () => {
      prevProps.isStatusChange = isStatusChange;
    }
  }, [isStatusChange]);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className='col-12 col-lg-4 col-md-6'>
        <Input type='text' placeholder='Search Title here' className='shadow-none' value={filterText} onChange={e => setFilterText(e.target.value)} />
      </div>
    );
  }, [filterText]);

  const handleSave = () => {
    dispatch(trainingActions.changeStatus(selectedData._id, !selectedData.active));
    setConfirmModal(false);
  };

  const column = [
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
    },
    {
      name: 'Training Date',
      selector: row => row.trainingDate ? moment(new Date(row.trainingDate)).format("DD-MM-YYYY") : '-',
      sortable: true,
    },
    {
      name: 'Created At',
      selector: row => moment(new Date(row.createdAt)).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => (<>
        <Badge className={`${row?.active ? 'bg-success' : 'bg-danger'}`}>{row?.active ? "Active" : "Inactive"}</Badge>
      </>),
      sortable: true,
    },
    {
      name: 'Action',
      selector: row => (<>
        <Link to={`/training/edit/${row?._id}`}>
          <FontAwesomeIcon icon={faPen} className='text-warning' style={{ cursor: "pointer" }} />
        </Link>
        <Button size='sm' style={{ marginLeft: "10px" }} color='primary' className='shadow-none' onClick={e => {
          setSelectedData(row);
          confirmToggle();
        }}>Change Status</Button>
      </>)
    }
  ];

  return (
    <React.Fragment>
      <Container fluid>
        {
          loading ?
            <Loader /> :
            <React.Fragment>
              <HeaderTitle buttonTex={"Create Training"} icon={faSchool} route={"/training/create"} title={"Training"} buttonIcon={faPlus} />
              <Row>
                <Col>
                  <DataTableComponent column={column} data={listData} subHeaderComponent={subHeaderComponentMemo} subHeader />
                </Col>
              </Row>
            </React.Fragment>
        }
      </Container>
      <ConfirmModal toggle={confirmToggle} modal={confirmModal} header={"Training"} handleSave={handleSave} loading={loading} />
    </React.Fragment>
  )
}

export default Training;