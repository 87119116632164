import axios from "axios";
import { toast } from "react-toastify";

export const GET_EVENT_LIST_REQUEST = 'GET_EVENT_LIST_REQUEST';
export const GET_EVENT_LIST_SUCCESS = 'GET_EVENT_LIST_SUCCESS';
export const GET_EVENT_LIST_FAILURE = 'GET_EVENT_LIST_FAILURE';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const GET_ONE_EVENT_REQUEST = 'GET_ONE_EVENT_REQUEST';
export const GET_ONE_EVENT_SUCCESS = 'GET_ONE_EVENT_SUCCESS';
export const GET_ONE_EVENT_FAILURE = 'GET_ONE_EVENT_FAILURE';

export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';

export const CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_FAILURE = 'CHANGE_STATUS_FAILURE';

export const getEventList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_EVENT_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/event/v1`;
            const response = await axios.get(url);
            dispatch({ type: GET_EVENT_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_EVENT_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const createEvent = (data, setData, confirmToggle) => {
    return async (dispatch) => {
        try {
            console.log('data', data)
            dispatch({ type: CREATE_EVENT_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/event/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_EVENT_SUCCESS, payload: response.data });
            setData({
                tags: [],
                title: "",
                displayImage: "",
                description: ""
            });
            confirmToggle();
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_EVENT_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getOneEvent = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ONE_EVENT_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/one/event/${id}/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_ONE_EVENT_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ONE_EVENT_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateEvent = (id, data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_EVENT_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/event/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_EVENT_SUCCESS, payload: response.data });
            navigate("/event");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_EVENT_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const changeStatus = (id, active) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_STATE" });
            dispatch({ type: CHANGE_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/event/${id}/v1`;
            const response = await axios.put(url, { active }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CHANGE_STATUS_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: CHANGE_STATUS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};