import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import JoditEditorComponent from '../../components/common/JoditEditorComponent';
import * as companyActions from "../../redux/company/company.actions";
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../lottie/Loader';
import { checkImageDimensions, uploadMulterImage } from '../../helper';
import { toast } from 'react-toastify';

const Company = () => {

  const [logoFile, setLogoFile] = useState(null);
  const [aboutFile, setAboutFile] = useState(null);
  const [newsFile, setNewsFile] = useState(null);
  const [articleFile, setArticleFile] = useState(null);
  const [eventFile, setEventFile] = useState(null);
  const [trainingFile, setTrainingFile] = useState(null);
  const [publicationFile, setPublicationFile] = useState(null);
  const [heroFile, setHeroFile] = useState(null);
  const [data, setData] = useState({
    name: "",
    logo: "",
    contact: "",
    email: "",
    landline: "",
    description: "",
    aboutImage: "",
    newsImage: "",
    articleImage: "",
    eventImage: "",
    trainingImage: "",
    publicationImage: "",
    heroImage: ""
  });
  const dispatch = useDispatch();

  const loading = useSelector(state => state.companyInfo.loading);
  const details = useSelector(state => state.companyInfo.details);

  useEffect(() => {
    dispatch(companyActions.getDetails());
  }, []);

  useEffect(() => {
    if (Object.keys(details).length > 0) {
      setData({
        ...data,
        name: details?.name,
        logo: "",
        contact: details?.contact,
        email: details?.email,
        landline: details?.landline,
        description: details?.description,
        aboutImage: details?.aboutImage,
        newsImage: details?.newsImage,
        articleImage: details?.articleImage,
        eventImage: details?.eventImage,
        trainingImage: details?.trainingImage,
        publicationImage: details?.publicationImage,
        heroImage: details?.heroImage
      });
    } else {
      setData({
        name: "",
        logo: "",
        contact: "",
        email: "",
        landline: "",
        description: "",
        aboutImage: "",
        newsImage: "",
        articleImage: "",
        eventImage: "",
        trainingImage: "",
        publicationImage: "",
        heroImage: ""
      });
    }
  }, [details]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    let logo = null;
    let aboutImage = null;
    let newsImage = null;
    let articleImage = null;
    let eventImage = null;
    let trainingImage = null;
    let publicationImage = null;
    let heroImage = null;
    if (logoFile) {
      const imageResponse = await uploadMulterImage(logoFile, 'company');
      if (imageResponse.status === false) {
        toast("Something went wrong with image");
        return;
      }
      logo = imageResponse.data;
    }
    if (aboutFile) {
      const checkImageDimension = await checkImageDimensions(aboutFile);
      if (!checkImageDimension.success) {
        toast.error(`The About Image must be below or equal to 600 pixels in width and 400 pixels in height. Current dimension is ${checkImageDimension.width} x ${checkImageDimension.height}.`);
        return;
      }
      const imageResponse = await uploadMulterImage(aboutFile, 'company');
      if (imageResponse.status === false) {
        toast("Something went wrong with image");
        return;
      }
      aboutImage = imageResponse.data;
    }
    if (newsFile) {
      const checkImageDimension = await checkImageDimensions(newsFile);
      if (!checkImageDimension.success) {
        toast.error(`The News Image must be below or equal to 600 pixels in width and 400 pixels in height. Current dimension is ${checkImageDimension.width} x ${checkImageDimension.height}.`);
        return;
      }
      const imageResponse = await uploadMulterImage(newsFile, 'company');
      if (imageResponse.status === false) {
        toast("Something went wrong with image");
        return;
      }
      newsImage = imageResponse.data;
    }
    if (articleFile) {
      const checkImageDimension = await checkImageDimensions(articleFile);
      if (!checkImageDimension.success) {
        toast.error(`The Article Image must be below or equal to 600 pixels in width and 400 pixels in height. Current dimension is ${checkImageDimension.width} x ${checkImageDimension.height}.`);
        return;
      }
      const imageResponse = await uploadMulterImage(articleFile, 'company');
      if (imageResponse.status === false) {
        toast("Something went wrong with image");
        return;
      }
      articleImage = imageResponse.data;
    }
    if (eventFile) {
      const checkImageDimension = await checkImageDimensions(eventFile);
      if (!checkImageDimension.success) {
        toast.error(`The Event Image must be below or equal to 600 pixels in width and 400 pixels in height. Current dimension is ${checkImageDimension.width} x ${checkImageDimension.height}.`);
        return;
      }
      const imageResponse = await uploadMulterImage(eventFile, 'company');
      if (imageResponse.status === false) {
        toast("Something went wrong with image");
        return;
      }
      eventImage = imageResponse.data;
    }
    if (trainingFile) {
      const checkImageDimension = await checkImageDimensions(trainingFile);
      if (!checkImageDimension.success) {
        toast.error(`The Training Image must be below or equal to 600 pixels in width and 400 pixels in height. Current dimension is ${checkImageDimension.width} x ${checkImageDimension.height}.`);
        return;
      }
      const imageResponse = await uploadMulterImage(trainingFile, 'company');
      if (imageResponse.status === false) {
        toast("Something went wrong with image");
        return;
      }
      trainingImage = imageResponse.data;
    }
    if (publicationFile) {
      const checkImageDimension = await checkImageDimensions(publicationFile);
      if (!checkImageDimension.success) {
        toast.error(`The Publication Image must be below or equal to 600 pixels in width and 400 pixels in height. Current dimension is ${checkImageDimension.width} x ${checkImageDimension.height}.`);
        return;
      }
      const imageResponse = await uploadMulterImage(publicationFile, 'company');
      if (imageResponse.status === false) {
        toast("Something went wrong with image");
        return;
      }
      publicationImage = imageResponse.data;
    }
    if (heroFile) {
      const imageResponse = await uploadMulterImage(heroFile, 'company');
      if (imageResponse.status === false) {
        toast("Something went wrong with image");
        return;
      }
      heroImage = imageResponse.data;
    }
    dispatch(companyActions.updateDetails({ ...data, logo: logo ? logo : data.logo, aboutImage: aboutImage ? aboutImage : data.aboutImage, newsImage: newsImage ? newsImage : data.newsImage, articleImage: articleImage ? articleImage : data.articleImage, eventImage: eventImage ? eventImage : data.eventImage, trainingImage: trainingImage ? trainingImage : data.trainingImage, publicationImage: publicationImage ? publicationImage : data.publicationImage, heroImage: heroImage ? heroImage : data.heroImage }));
  };

  return (
    <React.Fragment>
      {
        loading ?
          <Loader /> :
          <Container fluid>
            <div className="page-header d-print-none my-3">
              <div className="row g-2 align-items-center d-flex justify-content-between">
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <div className="d-inline-flex">
                    <span
                      className="bg-primary text-white rounded d-flex justify-content-center align-items-center"
                      style={{ width: 35, height: 35 }}
                    >
                      <FontAwesomeIcon icon={faBuilding} />
                    </span>
                    <h2
                      className="page-title ms-3 text-primary font-weight-bold text-capitalize"
                      style={{
                        textShadow: "0px .7px .5px lightgray",
                        wordWrap: "break-word"
                      }}
                    >
                      Company
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <form onSubmit={e => handleUpdate(e)}>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label>Company Name</Label>
                    <Input type='text' className='shadow-none' value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Company Logo</Label>
                    <Input type='file' className='shadow-none' accept="image/jpg, image/jpeg, image/png, image/webp" onChange={e => setLogoFile(e.target.files[0])} />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Company Email</Label>
                    <Input type='email' className='shadow-none' value={data.email} onChange={e => setData({ ...data, email: e.target.value })} />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Company Contact Number</Label>
                    <Input type='text' className='shadow-none' value={data.contact} onChange={e => setData({ ...data, contact: e.target.value })} />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Company Landline Number</Label>
                    <Input type='text' className='shadow-none' value={data.landline} onChange={e => setData({ ...data, landline: e.target.value })} />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>About Image{" "}<span className="text-danger">(Dimension : 600 x 400)</span></Label>
                    <Input type='file' className='shadow-none' accept="image/jpg, image/jpeg, image/png, image/webp" onChange={e => setAboutFile(e.target.files[0])} />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>News Image{" "}<span className="text-danger">(Dimension : 600 x 400)</span></Label>
                    <Input type='file' className='shadow-none' accept="image/jpg, image/jpeg, image/png, image/webp" onChange={e => setNewsFile(e.target.files[0])} />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Article Image{" "}<span className="text-danger">(Dimension : 600 x 400)</span></Label>
                    <Input type='file' className='shadow-none' accept="image/jpg, image/jpeg, image/png, image/webp" onChange={e => setArticleFile(e.target.files[0])} />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Event Image{" "}<span className="text-danger">(Dimension : 600 x 400)</span></Label>
                    <Input type='file' className='shadow-none' accept="image/jpg, image/jpeg, image/png, image/webp" onChange={e => setEventFile(e.target.files[0])} />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Training Image{" "}<span className="text-danger">(Dimension : 600 x 400)</span></Label>
                    <Input type='file' className='shadow-none' accept="image/jpg, image/jpeg, image/png, image/webp" onChange={e => setTrainingFile(e.target.files[0])} />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Publication Image{" "}<span className="text-danger">(Dimension : 600 x 400)</span></Label>
                    <Input type='file' className='shadow-none' accept="image/jpg, image/jpeg, image/png, image/webp" onChange={e => setPublicationFile(e.target.files[0])} />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Hero Image</Label>
                    <Input type='file' className='shadow-none' accept="image/jpg, image/jpeg, image/png, image/webp" onChange={e => setHeroFile(e.target.files[0])} />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <JoditEditorComponent value={data} setValue={setData} />
                </Col>
              </Row>
              <Button type='submit' color='primary' className='mt-3' block>Save</Button>
            </form>
          </Container>
      }
    </React.Fragment>
  )
}

export default Company;