import * as articleActions from "./article.actions";

export const articleFeatureKey = 'articleInfo';

const initialState = {
    loading: false,
    errorMsg: "",
    list: [],
    data: {},
    isStatusChange: false
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case articleActions.GET_ARTICLE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case articleActions.GET_ARTICLE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data
            }

        case articleActions.GET_ARTICLE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case articleActions.CREATE_ARTICLE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case articleActions.CREATE_ARTICLE_SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case articleActions.CREATE_ARTICLE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case articleActions.GET_ONE_ARTICLE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case articleActions.GET_ONE_ARTICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data
            }

        case articleActions.GET_ONE_ARTICLE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case articleActions.UPDATE_ARTICLE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case articleActions.UPDATE_ARTICLE_SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case articleActions.UPDATE_ARTICLE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case articleActions.CHANGE_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case articleActions.CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                isStatusChange: true
            }

        case articleActions.CHANGE_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "CLEAR_STATE":
            return {
                ...state,
                isStatusChange: false
            }

        default: return state;
    }
};