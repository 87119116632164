import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import * as adminActions from "../../redux/admin/admin.actions";
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'reactstrap';

const ResetPassword = () => {

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();

  const loading = useSelector(state => state.adminInfo.loading);

  const handleResetPassword = (e) => {
    e.preventDefault();
    dispatch(adminActions.resetNewPassword({ oldPassword, password: newPassword }, setOldPassword, setNewPassword, setConfirmPassword));
  };

  return (
    <React.Fragment>
      <div className="container-tight py-4">
        <div className="text-center">
          <a href="." className="navbar-brand navbar-brand-autodark">
            <img src="./assets/img/logo.svg" height={36} alt="" />
          </a>
        </div>
        <form
          className="card card-md"
          onSubmit={handleResetPassword}
        >
          <div className="card-body">
            <h2 className="card-title text-center mb-4">Change Password</h2>
            <div className="mb-0">
              <label className="form-label">Old Password</label>
              <input
                type="password"
                className="form-control"
                style={{ boxShadow: "none" }}
                tabIndex={1}
                placeholder="Old Password"
                name="username"
                autoComplete="off"
                value={oldPassword}
                onChange={e => setOldPassword(e.target.value)}
              />
            </div>
            <div className="mb-0 mt-3">
              <label className="form-label">New Password</label>
              <input
                type="password"
                className="form-control"
                style={{ boxShadow: "none" }}
                tabIndex={1}
                placeholder="New Password"
                name="username"
                autoComplete="off"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
              />
            </div>
            <div className="mb-0 mt-3">
              <label className="form-label">Confirm Password</label>
              <input
                type="password"
                className="form-control"
                style={{ boxShadow: "none" }}
                tabIndex={1}
                placeholder="New Password"
                name="username"
                autoComplete="off"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </div>
            <div className="form-footer mt-3 text-center">
              <button
                type="submit"
                className="btn btn-primary w-100 no-shadow mb-4"
                style={{ boxShadow: "none" }}
                tabIndex={2}
                id="sendOtpBtn__ID"
                disabled={loading}
              >
                {
                  loading ?

                    <Spinner
                      color="light"
                    >
                      Loading...
                    </Spinner>
                    :
                    "Save"
                }
              </button>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword;