import axios from "axios";
import { toast } from "react-toastify";

export const UPDATE_COMPANY_DETAILS_REQUEST = 'UPDATE_COMPANY_DETAILS_REQUEST';
export const UPDATE_COMPANY_DETAILS_SUCCESS = 'UPDATE_COMPANY_DETAILS_SUCCESS';
export const UPDATE_COMPANY_DETAILS_FAILURE = 'UPDATE_COMPANY_DETAILS_FAILURE';

export const GET_COMPANY_DETAILS_REQUEST = 'GET_COMPANY_DETAILS_REQUEST';
export const GET_COMPANY_DETAILS_SUCCESS = 'GET_COMPANY_DETAILS_SUCCESS';
export const GET_COMPANY_DETAILS_FAILURE = 'GET_COMPANY_DETAILS_FAILURE';

export const updateDetails = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_COMPANY_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/update/company/details/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: UPDATE_COMPANY_DETAILS_SUCCESS, payload: response.data });
            toast("Updated Successfully.");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_COMPANY_DETAILS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getDetails = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_COMPANY_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/company/details/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: GET_COMPANY_DETAILS_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_COMPANY_DETAILS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};