import axios from "axios";

export const uploadMulterImage = async (file, folder) => {
    try {
        const data = new FormData();
        data.append(folder, file);
        const url = `${process.env.REACT_APP_BASE_URL}/multer/upload/${folder}/file/v1`;
        const response = await axios.post(url, data, {
            headers: {
                Authorization: localStorage.getItem('token')
            }
        });
        if (response.data.status === 200) {
            return {
                success: true,
                data: response.data.data
            }
        } else {
            return {
                success: false,
                data: null
            };
        }
    } catch (error) {
        return {
            success: false,
            data: null
        };
    }
};

export const checkImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                if (img.height <= 400 && img.width <= 600) {
                    resolve({
                        success: true
                    });
                } else {
                    resolve({
                        success: false,
                        height: img.height,
                        width: img.width
                    });
                }
            };
            img.onerror = reject; // Handle errors related to image loading
            img.src = e.target.result;
        };
        reader.onerror = reject; // Handle errors related to file reading
        reader.readAsDataURL(file);
    });
};