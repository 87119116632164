import * as adminActions from "./admin.actions";

export const adminFeatureKey = "adminInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    admin: {}
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        // login admin
        case adminActions.LOGIN_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }

        case adminActions.LOGIN_ADMIN_SUCCESS:
            localStorage.setItem('token', payload.token);
            return {
                ...state,
                loading: false,
                admin: payload.data
            }

        case adminActions.LOGIN_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // forgot password
        case adminActions.FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }

        case adminActions.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case adminActions.FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // reset password
        case adminActions.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }

        case adminActions.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case adminActions.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // reset new password
        case adminActions.RESET_NEW_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            }

        case adminActions.RESET_NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case adminActions.RESET_NEW_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};