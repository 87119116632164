import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import * as purposeActions from "../../redux/purpose/purpose.actions";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import ConfirmModal from '../../components/common/ConfirmModal';
import { faArrowLeft, faAward, faCircleXmark, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import HeaderTitle from '../../components/common/HeaderTitle';
import JoditEditorComponent from '../../components/common/JoditEditorComponent';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../lottie/Loader';
import { uploadMulterImage } from '../../helper';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

const EditPurpose = () => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [file, setFile] = useState(null);
  const [backgroundFile, setBackgroundFile] = useState(null);
  const [videoLoading, setVideoLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    title: "",
    displayImage: "",
    description: "",
    smallDescription: "",
    videos: [],
    backgroundImage: ""
  });
  const [videoList, setVideoList] = useState([""]);
  const dispatch = useDispatch();
  const navigte = useNavigate();
  const { id } = useParams();

  const confirmToggle = () => setConfirmModal(!confirmModal);

  const oneData = useSelector(state => state.purposeInfo.data);
  const loading = useSelector(state => state.purposeInfo.loading);

  useEffect(() => {
    dispatch(purposeActions.getOnePurpose(id));
  }, [id]);

  useEffect(() => {
    if (oneData && Object.keys(oneData).length > 0) {
      setData({
        ...data,
        name: oneData.name,
        title: oneData.title,
        description: oneData.description,
        smallDescription: oneData.smallDescription,
        videos: oneData.videos,
        backgroundImage: oneData.backgroundImage,
        displayImage: oneData.displayImage
      });
      setVideoList(oneData?.videos?.length > 0 ? oneData.videos : [""]);
    }
  }, [oneData]);

  const handleUpdatePurpose = (e) => {
    e.preventDefault();
    confirmToggle();
  };

  const handleSave = async () => {
    let image = null;
    let backImage = null;
    if (file) {
      const imageResponse = await uploadMulterImage(file, 'purpose');
      if (imageResponse.status === false) {
        toast("Something went wrong with image");
        return;
      }
      image = imageResponse.data;
    }
    if (backgroundFile) {
      const imageResponse = await uploadMulterImage(backgroundFile, 'purpose');
      if (imageResponse.status === false) {
        toast("Something went wrong with image");
        return;
      }
      backImage = imageResponse.data;
    }
    if (videoList?.length > 1) {
      const viStatus = true;
      videoList.map(vi => {
        if (!vi) {
          viStatus = false;
        }
      });
      if (!viStatus) {
        toast.error("Please Add Video Link");
        return;
      }
      dispatch(purposeActions.updatePurpose(id, { ...data, videos: videoList, displayImage: image ? image : data.displayImage, backgroundImage: backImage ? backImage : data.backgroundImage }, navigte));
    } else {
      dispatch(purposeActions.updatePurpose(id, { ...data, videos: videoList[0] ? videoList : [], displayImage: image ? image : data.displayImage, backgroundImage: backImage ? backImage : data.backgroundImage }, navigte));
    }
  };

  // const handleUploadVideo = async (e) => {
  //   const videoFile = e.target.files[0];
  //   if (videoFile) {
  //     if (videoFile.size <= 50 * 1024 * 1024) {
  //       setVideoLoading(true);
  //       const imageResponse = await uploadMulterImage(videoFile, 'purpose');
  //       if (imageResponse.status === false) {
  //         toast("Something went wrong with image");
  //         return;
  //       }
  //       setVideoLoading(false);
  //       return imageResponse.data;
  //     } else {
  //       toast("File size exceeds the maximum limit (50 MB).");
  //       return false;
  //     }
  //   } else {
  //     toast("Something went wrong with video file");
  //     return false;
  //   }
  // };

  // const handleRemoveItem = (index) => {
  //   const updatedVideos = [...data.videos];
  //   updatedVideos.splice(index, 1);
  //   setData({ ...data, videos: updatedVideos });
  // };

  return (
    <React.Fragment>
      {
        loading ?
          <Loader /> :
          <React.Fragment>
            <Container fluid className='mt-3'>
              <HeaderTitle buttonTex={""} buttonIcon={faArrowLeft} icon={faAward} route={"/purpose"} title={"Edit Purpose"} />
              <Row>
                <Col>
                  {
                    oneData && Object.keys(oneData).length > 0 &&
                    <form onSubmit={e => handleUpdatePurpose(e)}>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="mb-3">
                            <label className="form-label required">Purpose Name</label>
                            <input type="text" className="form-control no-shadow" inputMode="text" autoComplete="off" required tabIndex="1" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="mb-3">
                            <label className="form-label required">Purpose Title</label>
                            <input type="text" className="form-control no-shadow" inputMode="text" autoComplete="off" required tabIndex="1" value={data.title} onChange={e => setData({ ...data, title: e.target.value })} />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="mb-3">
                            <label className="form-label required">Display Image</label>
                            <input type="file" className="form-control no-shadow" tabIndex="3" accept="image/jpg, image/jpeg, image/png, image/webp" onChange={e => setFile(e.target.files[0])} />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="mb-3">
                            <label className="form-label required">Background Image</label>
                            <input type="file" className="form-control no-shadow" tabIndex="3" accept="image/jpg, image/jpeg, image/png, image/webp" onChange={e => setBackgroundFile(e.target.files[0])} />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label required">Small Description</label>
                            <textarea className="form-control no-shadow" rows="3" required tabIndex="8" value={data.smallDescription} onChange={e => setData({ ...data, smallDescription: e.target.value })}></textarea>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label required">Description</label>
                            <JoditEditorComponent value={data} setValue={setData} />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            {/* <label className="form-label required">Videos</label> */}
                            {/* <input type="file" accept='video/*' className="form-control no-shadow" onChange={async e => {
                              const res = await handleUploadVideo(e);
                              if (res) {
                                data.videos.push(res);
                                setData(data);
                              }
                            }} /> */}
                            {/* {
                              videoList && videoList?.length > 0 && videoList.map((link, i) => (
                                <>
                                  <div key={i} style={{ width: "100%", display: "flex", justifyContent: "end", alignItems: "end" }}>
                                    {
                                      i === 0 ?
                                        <button type='button' className='btn btn-primary mb-3' onClick={e => {
                                          setVideoList([...videoList, ""]);
                                        }}>Add</button> :
                                        <button type='button' className='btn btn-danger mb-3 mt-3' onClick={e => {
                                          setVideoList(videoList.filter((vid, index) => index !== i));
                                        }}>Remove</button>
                                    }
                                  </div>
                                  <input type="text" className="form-control no-shadow" placeholder='Video Link' inputMode="text" autoComplete="off" tabIndex="1" value={videoList[i]} onChange={e => {
                                    const updatedVideoList = [...videoList];
                                    updatedVideoList[i] = e.target.value;
                                    setVideoList(updatedVideoList);
                                  }} />
                                </>
                              ))
                            } */}
                            <table class="table table-hover mt-3">
                              <thead>
                                <tr>
                                  <th scope='col' style={{ width: "90%" }}>Video Link</th>
                                  <th scope='col' style={{ width: "10%" }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  videoList && videoList?.length > 0 && videoList?.map((link, i) => (
                                    <tr key={i}>
                                      <td scope='row' style={{ width: "90%", verticalAlign: "middle" }}>
                                        <input type="text" className="form-control no-shadow" placeholder='Video Link' inputMode="text" autoComplete="off" tabIndex="1" value={videoList[i]} onChange={e => {
                                          const updatedVideoList = [...videoList];
                                          updatedVideoList[i] = e.target.value;
                                          setVideoList(updatedVideoList);
                                        }} />
                                      </td>
                                      <td style={{ width: "10%", verticalAlign: "middle" }}>
                                        {
                                          i === 0 ?
                                            <button type='button' className='btn btn-primary' onClick={e => {
                                              setVideoList([...videoList, ""]);
                                            }}><FontAwesomeIcon icon={faPlus} /></button> :
                                            <button type='button' className='btn btn-danger' onClick={e => {
                                              setVideoList(videoList.filter((vid, index) => index !== i));
                                            }}><FontAwesomeIcon icon={faMinus} /></button>
                                        }
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <div className='row'>
                          {
                            data.videos && data.videos.length > 0 && data.videos.map((v, i) => (
                              <div className='col-3' key={i} style={{ position: 'relative' }}>
                                <FontAwesomeIcon icon={faCircleXmark} style={{ position: 'absolute', top: 20, right: 20, cursor: 'pointer', zIndex: "9999" }} onClick={e => handleRemoveItem(i)} />
                                <video controls style={{ width: "100%", height: "auto" }}>
                                  <source src={`${imageBaseUrl}${v}`} />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            ))
                          }
                        </div> */}
                        <div className="col-12 text-center">
                          <button type="submit" className="btn btn-primary ms-auto shadow-none mb-3">Save</button>
                        </div>
                      </div>
                    </form>
                  }
                </Col>
              </Row>
            </Container>
            <ConfirmModal toggle={confirmToggle} modal={confirmModal} header={"Purpose"} handleSave={handleSave} loading={loading} />
          </React.Fragment>
      }
    </React.Fragment>
  )
};

export default EditPurpose;