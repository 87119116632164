import * as trainingActions from "./training.actions";

export const trainingFeatureKey = 'trainingInfo';

const initialState = {
    loading: false,
    errorMsg: "",
    list: [],
    data: {},
    isStatusChange: false
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case trainingActions.GET_TRAINING_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case trainingActions.GET_TRAINING_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data
            }

        case trainingActions.GET_TRAINING_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case trainingActions.CREATE_TRAINING_REQUEST:
            return {
                ...state,
                loading: true
            }

        case trainingActions.CREATE_TRAINING_SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case trainingActions.CREATE_TRAINING_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case trainingActions.GET_ONE_TRAINING_REQUEST:
            return {
                ...state,
                loading: true
            }

        case trainingActions.GET_ONE_TRAINING_SUCCESS:
            return {
                ...state,
                loading: false,
                data: payload.data
            }

        case trainingActions.GET_ONE_TRAINING_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case trainingActions.UPDATE_TRAINING_REQUEST:
            return {
                ...state,
                loading: true
            }

        case trainingActions.UPDATE_TRAINING_SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case trainingActions.UPDATE_TRAINING_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case trainingActions.CHANGE_STATUS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case trainingActions.CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                isStatusChange: true
            }

        case trainingActions.CHANGE_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "CLEAR_STATE":
            return {
                ...state,
                isStatusChange: false
            }

        default: return state;
    }
};