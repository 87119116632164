import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Login from "./pages/login/Login";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import ResetNewPassword from "./pages/resetNewPassword/ResetNewPassword";
import News from "./pages/news/News";
import Article from "./pages/article/Article";
import CreateArticle from "./pages/article/CreateArticle";
import EditArticle from "./pages/article/EditArticle";
import Tag from "./pages/tag/Tag";
import CreateNews from "./pages/news/CreateNews";
import EditNews from "./pages/news/EditNews";
import Event from "./pages/event/Event";
import CreateEvent from "./pages/event/CreateEvent";
import EditEvent from "./pages/event/EditEvent";
import Training from "./pages/training/Training";
import CreateTraining from "./pages/training/CreateTraining";
import EditTraining from "./pages/training/EditTraining";
import Purpose from "./pages/purpose/Purpose";
import CreatePurpose from "./pages/purpose/CreatePurpose";
import EditPurpose from "./pages/purpose/EditPurpose";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import Company from "./pages/company/Company";
import Contact from "./pages/conatct/Contact";
import ContactView from "./pages/conatct/ContactView";
import Subscribe from "./pages/subscribe/Subscribe";

const App = () => {
  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password/:resettoken" element={<ResetNewPassword />} />
          <Route path="/forgot/password" element={<ForgotPassword />} />
          <Route path="/" element={
            <ProtectedRoute>
              <Header />
            </ProtectedRoute>
          } />
          <Route path="/reset/password" element={
            <ProtectedRoute>
              <Header />
              <ResetPassword />
            </ProtectedRoute>
          } />
          <Route path="/company" element={
            <ProtectedRoute>
              <Header />
              <Company />
            </ProtectedRoute>
          } />
          <Route path="/tags" element={
            <ProtectedRoute>
              <Header />
              <Tag />
            </ProtectedRoute>
          } />
          <Route path="/news" element={
            <ProtectedRoute>
              <Header />
              <News />
            </ProtectedRoute>
          } />
          <Route path="/news/create" element={
            <ProtectedRoute>
              <Header />
              <CreateNews />
            </ProtectedRoute>
          } />
          <Route path="/news/edit/:id" element={
            <ProtectedRoute>
              <Header />
              <EditNews />
            </ProtectedRoute>
          } />
          <Route path="/article" element={
            <ProtectedRoute>
              <Header />
              <Article />
            </ProtectedRoute>
          } />
          <Route path="/article/create" element={
            <ProtectedRoute>
              <Header />
              <CreateArticle />
            </ProtectedRoute>
          } />
          <Route path="/article/edit/:id" element={
            <ProtectedRoute>
              <Header />
              <EditArticle />
            </ProtectedRoute>
          } />
          <Route path="/event" element={
            <ProtectedRoute>
              <Header />
              <Event />
            </ProtectedRoute>
          } />
          <Route path="/event/create" element={
            <ProtectedRoute>
              <Header />
              <CreateEvent />
            </ProtectedRoute>
          } />
          <Route path="/event/edit/:id" element={
            <ProtectedRoute>
              <Header />
              <EditEvent />
            </ProtectedRoute>
          } />
          <Route path="/training" element={
            <ProtectedRoute>
              <Header />
              <Training />
            </ProtectedRoute>
          } />
          <Route path="/training/create" element={
            <ProtectedRoute>
              <Header />
              <CreateTraining />
            </ProtectedRoute>
          } />
          <Route path="/training/edit/:id" element={
            <ProtectedRoute>
              <Header />
              <EditTraining />
            </ProtectedRoute>
          } />
          <Route path="/purpose" element={
            <ProtectedRoute>
              <Header />
              <Purpose />
            </ProtectedRoute>
          } />
          <Route path="/purpose/create" element={
            <ProtectedRoute>
              <Header />
              <CreatePurpose />
            </ProtectedRoute>
          } />
          <Route path="/purpose/edit/:id" element={
            <ProtectedRoute>
              <Header />
              <EditPurpose />
            </ProtectedRoute>
          } />
          <Route path="/contact/us" element={
            <ProtectedRoute>
              <Header />
              <Contact />
            </ProtectedRoute>
          } />
          <Route path="/subscribe" element={
            <ProtectedRoute>
              <Header />
              <Subscribe />
            </ProtectedRoute>
          } />
          <Route path="/contact/us/view/:id" element={
            <ProtectedRoute>
              <Header />
              <ContactView />
            </ProtectedRoute>
          } />
        </Routes>
      </Router>
    </React.Fragment>
  )
};

export default App;