import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as adminActions from "../../redux/admin/admin.actions";
import { Spinner } from 'reactstrap';

const ResetNewPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const resettoken = useParams().resettoken;
  const dispacth = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(state => state.adminInfo.loading);

  const handleSetNewPassword = (e) => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      toast("Please Enter your New Password.");
      return;
    }
    if (password !== confirmPassword) {
      toast("Password and Confirm Password Does not Match.");
      return;
    }
    dispacth(adminActions.setNewPassword(password, resettoken, navigate));
  };

  return (
    <React.Fragment>
      <div
        id="page-loader"
        style={{
          background:
            'rgba(0,0,0,0.75) url("<?= base_url()?>/img/loader.gif") no-repeat center center'
        }}
      ></div>
      <div className="page page-center">
        <div className="container-tight py-4">
          <div className="text-center mb-4">
            <a href="." className="navbar-brand navbar-brand-autodark">
              <img src="./assets/img/logo.svg" height={36} alt="" />
            </a>
          </div>
          <form
            className="card card-md"
            onSubmit={handleSetNewPassword}
          >
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Reset Password</h2>
              <div className="mb-0">
                <label className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  style={{ boxShadow: "none" }}
                  tabIndex={1}
                  placeholder="Enter Password"
                  name="Password"
                  autoComplete="off"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div className="mt-3">
                <label className="form-label">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  style={{ boxShadow: "none" }}
                  tabIndex={1}
                  placeholder="Enter Confirm Password"
                  name="Confirm Password"
                  autoComplete="off"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="form-footer mt-3 text-center">
                <button
                  type="submit"
                  className="btn btn-primary w-100 no-shadow mb-4"
                  style={{ boxShadow: "none" }}
                  tabIndex={2}
                  id="sendOtpBtn__ID"
                  disabled={loading}
                >
                  {
                    loading ?

                      <Spinner
                        color="light"
                      >
                        Loading...
                      </Spinner>
                      :
                      "Set Password"
                  }
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ResetNewPassword;