import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';

const ConfirmModal = ({ modal, toggle, header, handleSave, loading }) => {

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} centered size='sm'>
        <ModalHeader toggle={toggle}>
          {header}
        </ModalHeader>
        <ModalBody>
          Are you sure want to proceed?
        </ModalBody>
        <ModalFooter className='d-flex justify-content-center'>
          <Button color="primary" size='sm' onClick={handleSave}>
            {
              loading ?
                <Spinner
                  color="light"
                  size="sm"
                >
                  Loading...
                </Spinner> :
                "Save"
            }
          </Button>{' '}
          <Button color="secondary" size='sm' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ConfirmModal;