import React, { useState } from 'react';
import * as adminActions from "../../redux/admin/admin.actions";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'reactstrap';

const Login = () => {

  const [admin, setAdmin] = useState({
    email: "",
    password: ""
  });
  const [passwordType, setPasswordType] = useState("password");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(state => state.adminInfo.loading);

  const handleLogin = (e) => {
    e.preventDefault();
    if (!admin.email || !admin.password) {
      toast("Please fill fields");
      return;
    }
    dispatch(adminActions.adminLogin(admin, navigate));
  };

  return (
    <React.Fragment>
      <div className="page page-center ">
        <div className="container-tight py-4">
          <form
            className="card card-md"
            onSubmit={e => handleLogin(e)}
          >
            <div className="card-body">
              <p
                className="m-0 py-2 text-primary"
                style={{
                  fontSize: "2rem",
                  letterSpacing: 2,
                  fontFamily:
                    'Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif'
                }}
              >
                AMCed
              </p>
              <h2 className="card-title text-left mb-4">Sign In to Your Account</h2>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control no-shadow"
                  placeholder="Enter Email"
                  name="username"
                  autoComplete="off"
                  style={{ boxShadow: "none" }}
                  value={admin.email}
                  onChange={e => setAdmin({ ...admin, email: e.target.value })}
                />
                <div className="invalid-feedback d-block">
                </div>
              </div>
              <div className="mb-2">
                <label className="form-label">Password</label>
                <div
                  className="input-group input-group-flat"
                  style={{ boxShadow: "none" }}
                >
                  <input
                    type={passwordType}
                    className="form-control"
                    placeholder="Enter Password"
                    name="password"
                    autoComplete="off"
                    value={admin.password}
                    onChange={e => setAdmin({ ...admin, password: e.target.value })}
                  />
                  <span className="input-group-text">
                    {/* <i className="ti ti-eye icon" /> */}
                    <FontAwesomeIcon icon={passwordType === "text" ? faEyeSlash : faEye} style={{ cursor: "pointer" }} onClick={e => setPasswordType(passwordType === "password" ? "text" : "password")} />
                  </span>
                </div>
                <div className="invalid-feedback d-block">
                </div>
              </div>
              <div className="form-footer text-center">
                <button
                  type="submit"
                  className="btn btn-primary w-100 no-shadow mb-4"
                  disabled={loading}
                >
                  {
                    loading ?

                      <Spinner
                        color="light"
                      >
                        Loading...
                      </Spinner>
                      :
                      "Sign In"
                  }
                </button>
                <Link className="text-primary" style={{ fontWeight: 550 }} to="/forgot/password">
                  Forgot password ?
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login;