import React, { useRef } from 'react';
import DataTableComponent from '../../components/common/DataTableComponent';
import { Badge, Col, Container, Input, Row, Button } from 'reactstrap';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as contactActions from "../../redux/contact/contact.actions";
import { faMailBulk, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Loader from '../../lottie/Loader';
import { CSVLink } from "react-csv";
import ConfirmModal from '../../components/common/ConfirmModal';

const Contact = () => {

  const [filterText, setFilterText] = useState('');
  const [listData, setListData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const toggle = () => setModal(!modal);

  const loading = useSelector(state => state.contactInfo.loading);
  const list = useSelector(state => state.contactInfo.list);
  const isStatusChange = useSelector(state => state.contactInfo.isStatusChange);
  const prevProps = useRef({ isStatusChange }).current;

  useEffect(() => {
    dispatch(contactActions.getContactList());
  }, []);

  useEffect(() => {
    if (list.length > 0) {
      setListData(list);
      setCsvData([["ID", "Name", "Email", "Phone", "Company", "Message"],
      ...list.map(({ _id, name, email, phone, company, message }) => [
        _id,
        name,
        email,
        phone,
        company,
        message
      ]),]);
    } else {
      setListData([]);
    }
  }, [list]);

  useEffect(() => {
    if (list.length > 0) {
      if (filterText) {
        const filterData = list.filter(a => a.name.toLowerCase().includes(filterText.toLowerCase()) || a.email.toLowerCase().includes(filterText.toLowerCase()) || a.phone.toLowerCase().includes(filterText.toLowerCase()));
        setListData(filterData);
      } else {
        setListData(list);
      }
    }
  }, [filterText]);

  useEffect(() => {
    if (prevProps.isStatusChange !== isStatusChange) {
      if (isStatusChange) {
        dispatch(contactActions.getContactList());
      }
    }

    return () => {
      prevProps.isStatusChange = isStatusChange;
    }
  }, [isStatusChange]);

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <>
        <div className='col-md-6'>
          {
            csvData && csvData?.length > 0 &&
            <CSVLink style={{ marginRight: "20px" }} className="downloadbtn" filename="my-file.csv" data={csvData}>
              Export to CSV
            </CSVLink>
          }
        </div>
        <div className='col-md-6'>
          <Input type='text' placeholder='Search Title here' className='shadow-none' value={filterText} onChange={e => setFilterText(e.target.value)} />
        </div>
      </>
    );
  }, [filterText, csvData]);

  const handleDeleteContact = () => {
    dispatch(contactActions.contactDelete(selectedId, setSelectedId, setModal));
  };

  const column = [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: row => row.phone,
      sortable: true,
    },
    {
      name: 'Created At',
      selector: row => moment(new Date(row.createdAt)).format("DD-MM-YYYY hh:mm a"),
      sortable: true,
    },
    {
      name: 'Action',
      selector: row => (<>
        <Link to={`/contact/us/view/${row?._id}`}>
          <FontAwesomeIcon icon={faEye} className='text-warning' style={{ cursor: "pointer" }} />
        </Link>
        <span style={{ cursor: "pointer", marginLeft: "10px" }} onClick={e => {
          setSelectedId(row?._id);
          setModal(true);
        }}>
          <FontAwesomeIcon icon={faTrash} className='text-warning' style={{ cursor: "pointer" }} />
        </span>
      </>)
    }
  ];

  return (
    <React.Fragment>
      <Container fluid>
        {
          loading ?
            <Loader /> :
            <React.Fragment>
              <div className="page-header d-print-none my-3">
                <div className="row g-2 align-items-center d-flex justify-content-between">
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <div className="d-inline-flex">
                      <span
                        className="bg-primary text-white rounded d-flex justify-content-center align-items-center"
                        style={{ width: 35, height: 35 }}
                      >
                        <FontAwesomeIcon icon={faMailBulk} />
                      </span>
                      <h2
                        className="page-title ms-3 text-primary font-weight-bold text-capitalize"
                        style={{
                          textShadow: "0px .7px .5px lightgray",
                          wordWrap: "break-word"
                        }}
                      >
                        Contact Us
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <Row>
                <Col>
                  <DataTableComponent column={column} data={listData} subHeaderComponent={subHeaderComponentMemo} subHeader />
                </Col>
              </Row>
            </React.Fragment>
        }
      </Container>
      <ConfirmModal handleSave={handleDeleteContact} header={"Contact"} loading={false} modal={modal} toggle={toggle} />
    </React.Fragment>
  )
}

export default Contact;