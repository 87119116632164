import React, { useState } from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import * as tagActions from "../../redux/masterTag/tag.actions";
import * as newsActions from "../../redux/news/news.actions";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import ConfirmModal from '../../components/common/ConfirmModal';
import { faArrowLeft, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import HeaderTitle from '../../components/common/HeaderTitle';
import JoditEditorComponent from '../../components/common/JoditEditorComponent';
import Loader from '../../lottie/Loader';
import { checkImageDimensions, uploadMulterImage } from '../../helper';
import { toast } from 'react-toastify';

const CreateNews = () => {

  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState({
    tags: [],
    title: "",
    displayImage: "",
    description: "",
    shortDescription: "",
    newsDate: ""
  });
  const dispatch = useDispatch();

  const confirmToggle = () => setConfirmModal(!confirmModal);

  const list = useSelector(state => state.tagInfo.list);
  const tagLoading = useSelector(state => state.tagInfo.loading);
  const loading = useSelector(state => state.newsInfo.loading);

  useEffect(() => {
    dispatch(tagActions.getMasterTagList());
  }, []);

  useEffect(() => {
    if (list?.length > 0) {
      let arr = [];
      list.map(l => {
        arr.push({ value: l._id, label: l.name });
      });
      setOptions(arr);
    } else {
      setOptions([]);
    }
  }, [list]);

  const handleChange = (value) => {
    setSelectedOptions(value);
  };

  const handleAddNews = (e) => {
    e.preventDefault();
    confirmToggle();
  };

  const handleSave = async () => {
    if (!file) {
      toast("Please select Image");
      return;
    }
    const checkImageDimension = await checkImageDimensions(file);
    if (!checkImageDimension.success) {
      toast.error(`The News Image must be below or equal to 600 pixels in width and 400 pixels in height. Current dimension is ${checkImageDimension.width} x ${checkImageDimension.height}.`);
      return;
    }
    const imageResponse = await uploadMulterImage(file, 'news');
    if (imageResponse.status === false) {
      toast("Something went wrong with image");
      return;
    }
    dispatch(newsActions.createNews({ ...data, displayImage: imageResponse.data, tags: selectedOptions.map(s => s.value) }, setData, setSelectedOptions, confirmToggle));
  };

  return (
    <React.Fragment>
      <Container fluid className='mt-3'>
        <HeaderTitle buttonTex={""} buttonIcon={faArrowLeft} icon={faNewspaper} route={"/news"} title={"Create News"} />
        <Row>
          <Col>
            {
              loading || tagLoading ?
                <Loader /> :
                <React.Fragment>
                  <form onSubmit={e => handleAddNews(e)}>
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <div className="mb-3">
                          <label className="form-label required">News Title</label>
                          <input type="text" className="form-control no-shadow" inputMode="text" autoComplete="off" required tabIndex="1" value={data.title} onChange={e => setData({ ...data, title: e.target.value })} />
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="mb-3">
                          <label className="form-label">News Date</label>
                          <input type="date" className="form-control no-shadow" inputMode="text" autoComplete="off" tabIndex="1" value={data?.newsDate} onChange={e => setData({ ...data, newsDate: e.target.value })} />
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="mb-3">
                          <label className="form-label required">Display Image{" "}<span className="text-danger">(Dimension : 600 x 400)</span></label>
                          <input type="file" className="form-control no-shadow" tabIndex="3" accept="image/jpg, image/jpeg, image/png, image/webp" onChange={e => setFile(e.target.files[0])} />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label required">Tags</label>
                          <Select
                            value={selectedOptions}
                            onChange={handleChange}
                            options={options}
                            isMulti
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label required">Short Description</label>
                          <textarea className="form-control no-shadow" inputMode="text" autoComplete="off" required tabIndex="1" value={data.shortDescription} onChange={e => setData({ ...data, shortDescription: e.target.value })} ></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label required">Description</label>
                          <JoditEditorComponent value={data} setValue={setData} />
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        <button type="submit" className="btn btn-primary ms-auto shadow-none mb-3">Save</button>
                      </div>
                    </div>
                  </form>
                </React.Fragment>
            }
          </Col>
        </Row>
      </Container>
      <ConfirmModal toggle={confirmToggle} modal={confirmModal} header={"News"} handleSave={handleSave} loading={loading} />
    </React.Fragment>
  )
}

export default CreateNews;