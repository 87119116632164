import * as companyActions from "./company.actions";

export const companyFeatureKey = "companyInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    details: {}
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case companyActions.UPDATE_COMPANY_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case companyActions.UPDATE_COMPANY_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case companyActions.UPDATE_COMPANY_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case companyActions.GET_COMPANY_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case companyActions.GET_COMPANY_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                details: payload.data
            }

        case companyActions.GET_COMPANY_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};