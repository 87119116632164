import axios from "axios";
import { toast } from "react-toastify";

export const LOGIN_ADMIN_REQUEST = 'LOGIN_ADMIN_REQUEST';
export const LOGIN_ADMIN_SUCCESS = 'LOGIN_ADMIN_SUCCESS';
export const LOGIN_ADMIN_FAILURE = 'LOGIN_ADMIN_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const RESET_NEW_PASSWORD_REQUEST = 'RESET_NEW_PASSWORD_REQUEST';
export const RESET_NEW_PASSWORD_SUCCESS = 'RESET_NEW_PASSWORD_SUCCESS';
export const RESET_NEW_PASSWORD_FAILURE = 'RESET_NEW_PASSWORD_FAILURE';

export const adminLogin = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: LOGIN_ADMIN_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/login/admin/v1`;
            const response = await axios.post(url, data);
            dispatch({ type: LOGIN_ADMIN_SUCCESS, payload: response.data });
            toast("Login Successfully.");
            navigate("/");
        } catch (error) {
            console.error(error);
            dispatch({ type: LOGIN_ADMIN_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const forgotPassword = (email) => {
    return async (dispatch) => {
        try {
            dispatch({ type: FORGOT_PASSWORD_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/forgot/password/v1`;
            const response = await axios.post(url, { email });
            dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: response.data });
            toast("Email sent");
        } catch (error) {
            console.error(error);
            dispatch({ type: FORGOT_PASSWORD_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const setNewPassword = (password, resettoken, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_PASSWORD_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/set/new/password/v1/${resettoken}`;
            const response = await axios.post(url, { password });
            dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response.data });
            toast("Password reset successfully...");
            navigate("/login");
        } catch (error) {
            console.error(error);
            dispatch({ type: RESET_PASSWORD_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const resetNewPassword = (data, setOldP, setNewP, setConfirmP) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_NEW_PASSWORD_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/reset/password/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem("token")
                }
            });
            dispatch({ type: RESET_NEW_PASSWORD_SUCCESS, payload: response.data });
            toast("Password changed successfully...");
            setOldP("");
            setNewP("");
            setConfirmP("");
        } catch (error) {
            console.error(error);
            dispatch({ type: RESET_NEW_PASSWORD_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};