import axios from "axios";
import { toast } from "react-toastify";

export const GET_NEWS_LIST_REQUEST = 'GET_NEWS_LIST_REQUEST';
export const GET_NEWS_LIST_SUCCESS = 'GET_NEWS_LIST_SUCCESS';
export const GET_NEWS_LIST_FAILURE = 'GET_NEWS_LIST_FAILURE';

export const CREATE_NEWS_REQUEST = 'CREATE_NEWS_REQUEST';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_FAILURE = 'CREATE_NEWS_FAILURE';

export const GET_ONE_NEWS_REQUEST = 'GET_ONE_NEWS_REQUEST';
export const GET_ONE_NEWS_SUCCESS = 'GET_ONE_NEWS_SUCCESS';
export const GET_ONE_NEWS_FAILURE = 'GET_ONE_NEWS_FAILURE';

export const UPDATE_NEWS_REQUEST = 'UPDATE_NEWS_REQUEST';
export const UPDATE_NEWS_SUCCESS = 'UPDATE_NEWS_SUCCESS';
export const UPDATE_NEWS_FAILURE = 'UPDATE_NEWS_FAILURE';

export const CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_FAILURE = 'CHANGE_STATUS_FAILURE';

export const getNewsList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_NEWS_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/news/v1`;
            const response = await axios.get(url);
            dispatch({ type: GET_NEWS_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_NEWS_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const createNews = (data, setData, setSelectedOptions, confirmToggle) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CREATE_NEWS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/news/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_NEWS_SUCCESS, payload: response.data });
            setData({
                tags: [],
                title: "",
                displayImage: "",
                description: ""
            });
            setSelectedOptions([]);
            confirmToggle();
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_NEWS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getOneNews = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ONE_NEWS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/one/news/${id}/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_ONE_NEWS_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ONE_NEWS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updateNews = (id, data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_NEWS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/news/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_NEWS_SUCCESS, payload: response.data });
            navigate("/news");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_NEWS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const changeStatus = (id, active) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_STATE" });
            dispatch({ type: CHANGE_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/news/${id}/v1`;
            const response = await axios.put(url, { active }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CHANGE_STATUS_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: CHANGE_STATUS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};