import React from 'react';
import JoditEditor from 'jodit-react';
import { useRef } from 'react';

const JoditEditorComponent = ({ value, setValue }) => {

  const editor = useRef(null);

  const config = {
    placeholder: "Start Typing......",
    "toolbar": true,
    "showCharsCounter": false,
    "showWordsCounter": false,
    "showXPathInStatusbar": false,
    readonly: false,
    uploader: {
      insertImageAsBase64URI: true,
    },
    askBeforePasteHTML: false,
    askBeforePasteFromWord: true, //couse styling issues when pasting from word
    events: {
      afterOpenPasteDialog: (dialog, msg, title, callback) => {
        dialog.close()
        callback()
      },
    }
  }

  return (
    <>
      <JoditEditor
        ref={editor}
        value={value.description}
        onBlur={newContent => setValue({ ...value, description: newContent })}
        config={config}
      />
    </>
  )
}

export default JoditEditorComponent;