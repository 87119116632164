import axios from "axios";
import { toast } from "react-toastify";

export const GET_PURPOSE_LIST_REQUEST = 'GET_PURPOSE_LIST_REQUEST';
export const GET_PURPOSE_LIST_SUCCESS = 'GET_PURPOSE_LIST_SUCCESS';
export const GET_PURPOSE_LIST_FAILURE = 'GET_PURPOSE_LIST_FAILURE';

export const CREATE_PURPOSE_REQUEST = 'CREATE_PURPOSE_REQUEST';
export const CREATE_PURPOSE_SUCCESS = 'CREATE_PURPOSE_SUCCESS';
export const CREATE_PURPOSE_FAILURE = 'CREATE_PURPOSE_FAILURE';

export const GET_ONE_PURPOSE_REQUEST = 'GET_ONE_PURPOSE_REQUEST';
export const GET_ONE_PURPOSE_SUCCESS = 'GET_ONE_PURPOSE_SUCCESS';
export const GET_ONE_PURPOSE_FAILURE = 'GET_ONE_PURPOSE_FAILURE';

export const UPDATE_PURPOSE_REQUEST = 'UPDATE_PURPOSE_REQUEST';
export const UPDATE_PURPOSE_SUCCESS = 'UPDATE_PURPOSE_SUCCESS';
export const UPDATE_PURPOSE_FAILURE = 'UPDATE_PURPOSE_FAILURE';

export const CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_FAILURE = 'CHANGE_STATUS_FAILURE';

export const getPurposeList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PURPOSE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/purpose/v1`;
            const response = await axios.get(url);
            dispatch({ type: GET_PURPOSE_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PURPOSE_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const createPurpose = (data, setData, confirmToggle, setVideoList) => {
    return async (dispatch) => {
        try {
            dispatch({ type: CREATE_PURPOSE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/create/purpose/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CREATE_PURPOSE_SUCCESS, payload: response.data });
            setData({
                tags: [],
                title: "",
                displayImage: "",
                description: ""
            });
            confirmToggle();
            setVideoList([""]);
        } catch (error) {
            console.error(error);
            dispatch({ type: CREATE_PURPOSE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getOnePurpose = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ONE_PURPOSE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/get/purpose/${id}/v1`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: GET_ONE_PURPOSE_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ONE_PURPOSE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const updatePurpose = (id, data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_PURPOSE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/purpose/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: UPDATE_PURPOSE_SUCCESS, payload: response.data });
            navigate("/purpose");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_PURPOSE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const changeStatus = (id, active) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "CLEAR_STATE" });
            dispatch({ type: CHANGE_STATUS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/update/purpose/${id}/v1`;
            const response = await axios.put(url, { active }, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: CHANGE_STATUS_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: CHANGE_STATUS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};